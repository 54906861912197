<template>
  <div class="owner_set">
    <el-tabs type="border-card">
      <el-tab-pane label="基本设置">
        <div class="info_set" flex column align>
          <el-form
            :model="ruleForm_nickname"
            :rules="rules_nickname"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="昵称" prop="nick_name" v-if="is_edit_nickname!==1">
              <div flex align-end>
                <el-input
                  v-model="ruleForm_nickname.nick_name"
                  type="text"
                  v-focus
                  maxlength="10"
                ></el-input>
                <span style="color:#999;font-size:12px;margin-left:15px;min-width:35px;">{{ruleForm_nickname.nick_name.length}}/10</span>
                <span class="edit_name  edit_name_f">昵称只能修改一次哟~</span>
              </div>
            </el-form-item>

            <el-form-item label="昵称" prop="nick_name" v-else>
              <span>{{ nick_name }}</span>
               <span class="edit_name">如要修改昵称，请联系编辑~</span>
            </el-form-item>

            <el-form-item label="用户ID">
              <span>{{ person }}</span>
            </el-form-item>
            <!-- <div class="detail_item">
              <span class="detail_label">用户ID</span>
              <span>{{ person }}</span>
            </div> -->

            <el-form-item label="性别">
              <span>
                <el-radio v-model="radio" :label="1">男</el-radio>
                <el-radio v-model="radio" :label="2">女</el-radio>
                <el-radio v-model="radio" :label="0">保密</el-radio>
              </span>
             </el-form-item>

            <!-- <div class="detail_item">
              <span class="detail_label">性别</span>
              <span>
                <el-radio v-model="radio" :label="1">男</el-radio>
                <el-radio v-model="radio" :label="2">女</el-radio>
                <el-radio v-model="radio" :label="0">保密</el-radio>
              </span>
            </div> -->
          </el-form>
          <div class="btm_btn">
            <el-button
              type="primary"
              style="padding: 8px 15px"
              @click="submit(1)"
              >提 交</el-button
            >
            <!-- v-if="is_disabled_base" -->
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="头像设置">
        <div class="header_up">
          <div class="header_top">
            <div class="avator">
              <div class="header_avator up_img">
                <upPic v-model="cover_image" :up_type="'system'"></upPic>
              </div>
            </div>
            <div class="avator avator_y">
              <img
                :src="person_info.avatar || header_avator"
                alt=""
                class="header_avator"
              />
            </div>
          </div>
          <span class="warning_word"
            >请选择图片上传（大小为180 *
            180像素，支持JPG、PNG等格式，图片需小于2M）</span
          >
          <div class="btm_btn">
            <el-button
              type="primary"
              style="padding: 8px 15px"
              @click="submit(2)"
              >更新头像</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="修改密码">
        <div class="change_password" flex column align>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="原密码" prop="old_pwd" placeholder="请输入密码">
              <el-input
                v-model="ruleForm.old_pwd"
                type="password"
                v-focus
                maxlength="16"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_pwd1" placeholder="请输入新密码(6-16位数字和字母组合)">
              <el-input
                v-model="ruleForm.new_pwd1"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="new_pwd2" placeholder="请确认新密码(6-16位数字和字母组合)">
              <el-input
                v-model="ruleForm.new_pwd2"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btm_btn">
            <el-button
              type="primary"
              style="padding: 8px 15px"
              @click="submit(3)"
              >确认修改</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="修改手机号">
        <div class="change_phone">
          <el-steps
            :active="active"
            finish-status="success"
            :align-center="true"
          >
            <el-step title="验证旧手机号"></el-step>
            <el-step title="绑定新手机号"></el-step>
          </el-steps>
          <!-- 第一步获取验证码： -->
          <div :class="active === 0 ? 'change_phone_step1' : 'un_show'">
            <p>为了您的账户安全，需要验证你的手机</p>
            <p>当前账户手机号{{ cur_phone }}</p>
            <el-form
              :model="rules_form_code"
              :rules="rules_code"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="" prop="code1">
                <!-- v-model="check_val1" -->
                <el-input
                  placeholder="请输入短信验证码"
                  v-model="rules_form_code.code1"
                  v-focus
                  class="input-with-select"
                  type="number"
                  maxlength="6"
                >
                  <el-button
                    slot="append"
                    @click="get_check1(person_info.phone)"
                    type="primary"
                    class="check_code_btn"
                  >
                    {{ is_click1 ? set_time1 + "s" : ""
                    }}{{ reg_word1 }}</el-button
                  >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            :class="active === 1 ? 'change_phone_step2' : 'un_show'"
            flex
            column
            around
          >
          <!-- 第二步获取验证码 -->
            <el-form
              :model="rules_form_code"
              :rules="rules_code"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="" prop="phone_num">
                <el-input
                  placeholder="请输入新手机号"
                  v-model="rules_form_code.phone_num"
                  v-focus
                  maxlength="11"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="code2">
                <el-input
                  placeholder="请输入短信验证码"
                  v-model="rules_form_code.code2"
                  class="input-with-select"
                  type="number"
                  maxlength="6"
                >
                  <el-button
                    slot="append"
                    @click="get_check2(rules_form_code.phone_num)"
                    type="primary"
                    class="check_code_btn"
                  >
                    {{ is_click2 ? set_time2 + "s" : ""
                    }}{{ reg_word2 }}</el-button
                  >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="change_phone_button">
            <!-- 底部按钮 -->
            <el-button
              class="go_back"
              style="margin-top: 12px"
              @click="pre"
              v-show="active === 1"
              type="primary"
              plain
              >返回</el-button
            >
            <el-button
              style="margin-top: 12px"
              @click="
                active === 1
                  ? change_phone(
                      rules_form_code.phone_num,
                      rules_form_code.code2
                    )
                  : check_code(person_info.phone, rules_form_code.code1, 1)
              "
              v-text="active === 1 ? '立即绑定' : '下一步'"
              type="primary"
              v-if="active > 1 ? false : true"
              :class="active === 1 ? 'binding' : ''"
            ></el-button>
            <p v-if="active > 1" class="succcess_change">绑定成功!</p>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import textInput from "@/components/input/title_input";
import upPic from "@/components/input/up_pic";
import {
  PersonUpbase,
  Moneyinfo,
  Sendmassage,
  ChactCode,
} from "@/https/mine_center.js";
export default {
  name: "",
  components: {
    textInput,
    upPic,
  },
  data() {
    return {
      is_click1: false,
      is_click2: false,
      check_val1: "",
      check_val2: "",
      reg_word1: "获取验证码",
      reg_word2: "获取验证码",
      time_init1: null,
      time_init2: null,
      set_time1: 60,
      set_time2: 60,
      time_type1: false,
      time_type2: false,
      cur_phone: "",
      person_info: JSON.parse(sessionStorage.getItem("person_info")),
      person: sessionStorage.getItem("person_id"),
      phone: {
        label: "手机号",
        text: "",
      },
      radio: 1,
      cover_image: "",
      header_avator: require("@/assets/images/default_header.png"),
      isRouterAlive: true,
      active: 0,
      author: this.is_author(),
      ruleForm: {
        old_pwd: "",
        new_pwd1: "",
        new_pwd2: "",
      },
      nick_name: "",
      author_name: "",
      rules: {
        old_pwd: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          // { required: true, validator: this.old_pwd, trigger: "blur" },
          {
            required: true,
            max: 16,
            min: 6,
            message: "密码长度在6到16个字符",
            trigger: "blur",
          },
        ],
        new_pwd1: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { required: true, validator: this.new_pwd1, trigger: "blur" },
          {
            required: true,
            max: 16,
            min: 6,
            message: "密码长度在6到16个字符",
            trigger: "blur",
          },
        ],
        new_pwd2: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { required: true, validator: this.new_pwd2, trigger: "blur" },
          {
            required: true,
            max: 16,
            min: 6,
            message: "密码长度在6到16个字符",
            trigger: "blur",
          },
        ],
        author_name: "",
        nick_name: "",
      },
      ruleForm_nickname: {
        nick_name: "",
        author_name: "",
      },
      rules_nickname: {
        nick_name: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
          {
            required: true,
            max: 10,
            min: 1,
            message: "昵称最多10个字符",
            trigger: "blur",
          },
        ],
        author_name: [
          { required: true, message: "笔名不能为空", trigger: "blur" },
        ],
      },
      rules_form_code: {
        code1: "",
        code2: "",
        phone_num: "",
      },
      rules_code: {
        code1: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          { required: true, validator: this.code1, trigger: "blur" },
        ],
        code2: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          { required: true, validator: this.code2, trigger: "blur" },
        ],
        phone_num: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { required: true, validator: this.phone_num, trigger: "blur" },
        ],
      },
      is_edit_nickname:''
      // is_disabled_base:false
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].focus();
      },
    },
  },
  methods: {
    next() {
      ++this.active;
      if (this.active > 1) this.active = 0;
    },
    pre() {
      --this.active;
      if (this.active < 0) this.active = 0;
    },
    // 数据回显
    aviod_info() {
      Moneyinfo().then((res) => {
        console.log(res);
        this.nick_name = res.data.nickname;
        this.author_name = res.data.author_name;
        this.ruleForm_nickname.nick_name = res.data.nickname;
        this.ruleForm_nickname.author_name = res.data.author_name;
        this.radio = res.data.sex;
        this.is_edit_nickname=res.data.is_edit_nickname
        if(!this.person_info){
          this.person_info = res.data;
          console.log("...***",res.data)
        }else{
          console.log("-----",this.person_info)
        }
        // this.person_info.avatar = res.data.avatar;
        
        this.is_disabled_base = false;
      });
    },
    // 验证码规则：不能为空、仅能数字、6位数字
    code1(rule, value, callback) {
      var regx = new RegExp(/^\d{6}$/g);
      console.log(1, value);
      if (!regx.test(value)) {
        console.log(2, value);
        callback(new Error("验证码为6位数字"));
      }
      callback();
    },
    // 验证新手机号格式
    phone_num(rule, value, callback) {
      var regx = new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/g);
      console.log(value);
      if (!regx.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else if (value == this.rules_form_code.phone_num) {
        callback(new Error("不能填写当前账户手机号"));
      }
      callback();
    },
    code2(rule, value, callback) {
      var regx = new RegExp(/^\d{6}$/g);
      console.log(value);
      if (!regx.test(value)) {
        callback(new Error("验证码为6位数字"));
      }
      callback();
    },
    new_pwd1(rule, value, callback) {
      var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      console.log(!reg.test(value));
      if (!reg.test(value)) {
        callback(new Error("密码格式不正确(6-16位数字和字母组合)"));
      }
      callback();
    },
    new_pwd2(rule, value, callback) {
      var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      console.log(!reg.test(value));
      if (!reg.test(value)) {
        callback(new Error("密码格式不正确(6-16位数字和字母组合)"));
      }
      if (!(this.ruleForm.new_pwd1 === value)) {
        callback(new Error("两次输入密码不同"));
      }
      callback();
    },
    // 提交修改
    async submit(num) {
      let props = {
        table: num,
      };
      if (num === 1) {
        props.nickname = this.ruleForm_nickname.nick_name;
        // if (this.is_author) {
        //   props.author_name =
        //     this.ruleForm_nickname.author_name || this.author_name;
        // } else {
        //   props.author_name = "";
        // }
        console.log(this.ruleForm_nickname.nick_name);
        props.sex = this.radio;
      }
      if (num === 2) {
        if (this.cover_image) {
          props.avatar = this.cover_image;
        }
      }
      if (num === 3) {
        props.oldpassword = this.ruleForm.old_pwd;
        props.newPassworda = this.ruleForm.new_pwd1;
        props.newPasswordb = this.ruleForm.new_pwd2;
      }
      PersonUpbase(props).then((res) => {
        if (res.code === 0) {
          // this.$message.success("修改成功");
          if (num === 3) {
            this.$message.success("检测到密码发生改变，请重新登录!");
            this.log_out();
          }
          if (num === 1) {
            this.person_info.nickname = this.ruleForm_nickname.nick_name;
            sessionStorage.setItem(
              "person_info",
              JSON.stringify(this.person_info)
            );
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            Moneyinfo().then((res) => {
              console.log(res.data);
            });
            // this.$router.go(0);
            window.location.reload()
          }
          if (num === 2) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            // 将session中的用户信息头像更改
            this.person_info.avatar = this.cover_image;
            Moneyinfo().then((res) => {
              sessionStorage.setItem("person_info", JSON.stringify(res.data));
              // this.$router.go(0);
              window.location.reload()
            });
          }
        } else {
          this.$message.error(`${res.msg},修改失败！`);
        }
      });
    },
    // 绑定手机号提交修改
    async change_phone(phone, check) {
      let params = {
        newphone: String(phone),
        captcha: Number(check),
        table: 2,
      };
      ChactCode(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("检测到手机号发生改变，请重新登录!");
          ++this.active;
          this.log_out();
        }
      });
    },
    // 检测密码格式
    check_pwd(pwd) {
      let regx = /^(?![^a-zA-Z]+$)(?!\D+$)/;
      if (!regx.test(pwd)) {
        return false;
      }
      return true;
    },
    // 第一步获取验证码：
    get_check1(num) {
      let props = {
        phone: num,
      };
      Sendmassage(props).then((res) => {
        if (res.code === 0) {
          this.time_type1 = true;
          this.reg_word1 = "重新获取验证码";
          this.is_click1 = true;
          this.time_init1 = setInterval(() => {
            if (this.set_time1 >= 1) {
              this.set_time1 -= 1;
            } else {
              this.set_time1 = 60;
              this.time_type1 = false;
              // this.code_clear_time();
              clearInterval(this.time_init1);
              this.time_init1 = null;
            }
          }, 1000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 第二步获取验证码
    get_check2(num) {
      let props = {
        newphone: num,
      };
      console.log(props);
      let is_phone = /^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$/g.test(num);
      let that = this;
      if (is_phone) {
        Sendmassage(props).then((res) => {
          if (res.code === 0) {
            this.time_type2 = true;
            this.reg_word2 = "重新获取验证码";
            this.is_click2 = true;
            this.time_init2 = setInterval(() => {
              if (that.set_time2 >= 1) {
                that.set_time2 -= 1;
              } else {
                this.set_time2 = 60;
                that.time_type2 = false;
                clearInterval(this.time_init2);
                this.time_init2 = null;
              }
            }, 1000);
          } else {
            this.$message.warning(res.msg);
          }
        });
      } else {
        if (num === "") {
          this.$message.warning("手机号码不能为空!");
        } else {
          this.$message.warning("手机号码格式有误，请重填");
        }
      }
    },
    code_clear_time() {
      clearInterval(this.time_init);
      this.time_init = null;
    },
    // 下一步
    check_code(phone, num, type) {
      let params = {
        phone: String(phone),
        captcha: Number(num),
        table: type,
      };
      console.log("382行", params);
      ChactCode(params).then((res) => {
        if (res.code === 0) {
          this.next();
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
    // 强制退出登录
    log_out() {
      // function* gener(){}
      sessionStorage.removeItem("person_info");
      localStorage.removeItem("api_token")
      this.$store.commit("token", "");
      sessionStorage.clear();
      sessionStorage.setItem("menu_id", 1);
      this.$router.push("/index");
      // this.$router.go(0);
      window.location.reload()
    },
  },
  created() {
    this.aviod_info();
    for (let i = 0; i < this.person_info.phone.length; i++) {
      if (i > 2 && i < 7) {
        this.cur_phone = this.cur_phone + "*";
      } else {
        this.cur_phone = this.cur_phone + this.person_info.phone[i];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.owner_set {
  // box-shadow: 0 0 5px #e4e4e4;
}
/deep/ .el-tabs--border-card {
  border: none;
  box-shadow: none;
}
.info_set {
  margin-top: 80px;
  /deep/.label,
  /deep/.el-form-item__label {
    width: 40px;
  }
  .el-form {
    position: relative;
    left: -30px;
  }
  /deep/.el-input{
    width: 220px !important
  }
  .edit_name{
    margin-left: 50px;
    color: #cacaca;
  }
  .edit_name_f{
    color: #ff4949
  }
  //  > div {
  //    margin: 0 auto;
  //  }
  .detail_item {
    padding: 10px 0;
    @include flex-row();
    .detail_label {
      display: flex;
      margin-right: 8px;
      width: 50px;
      justify-content: center;
    }
  }
}
.btm_btn {
  text-align: center;
  margin-top: 100px;
}

.header_up {
  @include flex-column-center();
  .header_top {
    @include flex-row();
    justify-content: center;
  }
  .avator {
    padding: 30px 80px;
  }
  .avator_y {
    border-left: 1px solid #eeeeee;
  }
  .header_avator {
    width: 150px;
    height: 150px;
  }

  .up_img {
    border: 1px solid #eeeeee;
    box-shadow: 0 0 5px #eeeeee;
    border-radius: 2px;
  }
  .warning_word {
    padding: 20px 0 80px;
    font-size: 12px;
  }
}

.change_password {
  .demo-ruleForm {
    width: 50%;
    display: inline-block;
    margin: 0 auto;
  }
  margin-top: 80px;
}

.change_password,
.change_phone {
  /deep/.label {
    width: 100px;
  }
  .btm_btn {
    text-align: center;
    margin-top: 100px;
  }
}
.change_phone {
  .un_show {
    display: none;
  }
  .change_phone_step1,
  .change_phone_step2 {
    margin: 50px 0 20px 0;
    width: 100%;
    padding: 20px 200px;
    text-align: center;
    p {
      color: #999;
      font-size: 12px;
    }
    .el-input {
      margin: 10px;
    }
  }
  .change_phone_step2 {
    /deep/ .el-input > .el-input__inner::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  .change_phone_button {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    .binding {
    }
    .go_back {
      margin-right: 50px;
    }
  }
  .succcess_change {
    color: #67c23a;
    font-size: 18px;
    margin-top: 100px;
  }
  /deep/ .el-button {
    background-color: #409eff;
    border: none;
    color: #fff;
    margin: -10px -22px;
  }
  /deep/ .el-input__inner {
    height: 38px !important;
  }
  .check_code_btn {
    border-radius: 0 5px 5px 0;
  }
  .el-steps {
    margin-top: 60px;
  }

  .input-with-select {
    /deep/ {
      .el-input__inner::-webkit-outer-spin-button,
      .el-input__inner::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }
}

/deep/.is-process {
  color: #666666 !important;
  border-color: #666666 !important;
  /deep/.el-step__line-inner {
    color: #666666 !important;
  }
  /deep/.el-step__icon {
    color: #666666 !important;
    border-color: #666666 !important;
  }
}

/deep/.is-success {
  color: #409eff !important;
  border-color: #409eff !important;
  /deep/.el-step__line-inner {
    color: #409eff !important;
  }
  /deep/.el-step__icon {
    color: #409eff !important;
    border-color: #409eff !important;
  }
}
</style>