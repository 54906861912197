<template>
  <div class="text_input">
    <span class="label">{{option.label||" "}}</span>
    <el-input v-model="option.text" :placeholder="placeholder" :type="type" v-focus @keyup.enter.native="enter_next"></el-input>
  </div>
</template>
<script>
export default {
  name:'textInput',
  props:{
    placeholder:{
      type:String,
      default:'请输入'
    },
    option:{
      type:Object
    },
    type:{
      type:String,
      default:'text'
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].focus();
      },
    },
  },
  methods:{
    enter_next(e){
      this.$emit('enter_next',e);
    },
  }
}
</script>
<style scoped lang="scss">
@import "../../utils/tool.scss";
  .text_input{
    @include flex-row();
    margin: 5px 5px 5px 0;
    .label{
      @include flex-row-end();
      margin-right: 8px;
    }
    /deep/.el-input{
      width: 200px
    }
  }
</style>


