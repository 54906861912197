<template>
  <div class="up_box">
    <input
      type="file"
      accept="image/*"
      @change="get_url($event)"
      id="file_ipt"
      ref="ipt_file"
    />
    <div class="bc_over">{{ url ? "更换图片" : "点击上传图片" }}</div>
    <div class="bc_add" v-if="!url">
      <i class="iconfont icon-add"></i>
    </div>
    <img
      :src="url"
      v-loading="url ? false : true"
      v-if="url"
      alt=""
      class="up_img"
    />
  </div>
</template>
<script>
import { upload_img } from "@/https/api.js";
export default {
  name: "upPic",
  props: {
    value: [String, Array],
    up_type: {
      type: String,
      default: "bookCover",
    },
  },
  data() {
    return {
      url: "",
    };
  },
  watch: {
    value() {
      this.url = JSON.parse(JSON.stringify(this.value));
    },
  },
  created() {
  },
  methods: {
    get_url(event) {
      let file = event.target.files[0];
      let that = this;
      let big_bag = file.size < 1024 * 1024 * 2 ? true : false;
      let type_1 = file.type === "image/png" ? true : false;
      let type_2 = file.type === "image/jpeg" ? true : false;
      if (file && big_bag) {
        if (type_1 || type_2) {
          var reader = new FileReader();
          // 图片文件转换为base64
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            let url = this.result.split(",")[1];
            let props = {
              type: that.up_type,
              file: url,
            };
            upload_img(props).then((res) => {
              if (res.code === 0) {
                that.url = res.data;
                that.$emit("input", res.data);
              }
            });
          };
        } else {
          this.$message.warning("文件格式不是png/jpeg格式");
          this.$refs.ipt_file.value = "";
        }
      } else {
        this.$message.error("图片大小超过2M");
        this.$refs.ipt_file.value = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.up_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  cursor: pointer;
  // border:1px solid #eeeeee;
  #file_ipt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
  }
  .bc_over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
  }
  .bc_add {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 30px;
      color: #c3c3c3;
    }
  }
  .up_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
.up_box:hover .bc_over {
  display: flex;
}
</style>


